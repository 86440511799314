import React, {Component} from "react";
import "./ScanNews.css";


class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        return (
            <div>
                <figure className="highlight">
                    <h2>Terms of Service</h2>
                </figure>
                <table className="table">
                    <tbody>
                    <tr>
                        <td>
                            <h3>Thank you for using service!</h3>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>
                                These Terms of Service ("Terms") govern your access to and use of NextMining's
                                website, products, and services.
                                &nbsp;Please read these Terms carefully, and contact us if you have any questions.
                                &nbsp;By accessing or using our Products, you agree to be bound by these Terms and by
                                our Privacy Policy.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <h3>1. Using ScanNews</h3>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <h4>a. Who can use ScanNews</h4>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>
                                You may use our Products only in compliance with these Terms and all applicable laws.
                                &nbsp;When you create your NextMining account, you must provide us with accurate and
                                complete information.
                                &nbsp;If you open an account on behalf of a company, organization, or other entity,
                                &nbsp;then (a) "you" includes you and that entity, and (b) you represent and warrant
                                &nbsp;that you are authorized to grant all permissions and licenses provided in these
                                Terms
                                &nbsp;and bind the entity to these Terms, and that you agree to these Terms on the
                                entity's behalf.
                                &nbsp;Some of our Products may be software that is downloaded to your computer, phone,
                                tablet, or other device.
                                &nbsp;You agree that we may automatically upgrade those Products, and these Terms will
                                apply to such upgrades.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <h4>b. Our license to you</h4>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>
                                Subject to these Terms and our policies (including our Acceptable Usage Policy),
                                &nbsp;we grant you a limited, non-exclusive, non-transferable, and revocable license to
                                use our Products.
                            </p>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <h3>2. Your Content</h3>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <h4>a. User content</h4>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>
                                If you save your content on ScanNews, it still belongs to you but we can use or show it
                                to people.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <h4>b. How long we keep your content</h4>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>
                                Following termination or deactivation of your account, or if you remove any User Content
                                from ScanNews,
                                &nbsp;we may retain your User Content for a commercially reasonable period of time for
                                backup, archival, or audit purposes.
                            </p>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <h3>3. Copyright Policy</h3>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>
                                We respect copyrights. You should, too.
                            </p>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <h3>4. Security</h3>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>
                                We care about the security of our users.
                                &nbsp;While we work to protect the security of your content and account,
                                &nbsp;NextMining cannot guarantee that unauthorized third parties will not be able to
                                defeat our security measures.
                                &nbsp;Please notify us immediately of any compromise or unauthorized use of your
                                account.
                            </p>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <h3>5. Termination</h3>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>
                                NextMining may terminate or suspend this license at any time, with or without cause or
                                notice to you.
                                &nbsp;We reserve the right to refuse service to anyone.
                            </p>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <h3>6. Limitation of Liability</h3>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>
                                We are building the best service we can for you but we can't promise it will be perfect.
                                &nbsp;We're not liable for various things.
                            </p>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <h3>7. General Terms</h3>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>
                                <strong>Notification Procedures and changes to these Terms.</strong>
                                &nbsp;NextMining reserves the right to determine the form and means of providing
                                notifications to you,
                                &nbsp;and you agree to receive legal notices electronically if we so choose.
                                &nbsp;We may revise these Terms from time to time and the most current version will
                                always be posted on our website.
                                &nbsp;If a revision, in our sole discretion, is material we will notify you.
                                &nbsp;By continuing to access or use the Products after revisions become effective,
                                &nbsp;you agree to be bound by the revised Terms.
                                &nbsp;If you do not agree to the new terms, please stop using the Products.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>
                                <strong>Assignment.</strong>
                                &nbsp;These Terms, and any rights and licenses granted hereunder, may not be transferred
                                or assigned by you,
                                &nbsp;but may be assigned by NextMining without restriction.
                                &nbsp;Any attempted transfer or assignment in violation hereof shall be null and void.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>
                                <strong>Entire Agreement/Severability.</strong>
                                &nbsp;These Terms, together with the Privacy Policy and any amendments and any
                                additional agreements
                                &nbsp;you may enter into with NextMining in connection with the Products,
                                &nbsp;shall constitute the entire agreement between you and NextMining concerning the
                                Products.
                                &nbsp;If any provision of these Terms is deemed invalid,
                                &nbsp;then that provision will be limited or eliminated to the minimum extent necessary,
                                &nbsp;and the remaining provisions of these Terms will remain in full force and effect.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>
                                <strong>No Waiver.</strong>
                                No waiver of any term of these Terms shall be deemed a further or continuing waiver of
                                such term or any other term, and NextMining's failure to assert any right or provision
                                under these Terms shall not constitute a waiver of such right or provision.
                            </p>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <h3>8. Use of YouTube API Services</h3>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>
                                ScanNews application is using YouTube API services for playing most of the video
                                contents.
                                Hence, ScanNews application follows YouTube's Terms of Service (<a
                                href="https://www.youtube.com/t/terms">https://www.youtube.com/t/terms</a>).
                                ScanNews users also agree to a Google Privacy Policy (<a
                                href="http://www.google.com/policies/">http://www.google.com/policies/</a>) as we are
                                using YouTube API service.
                            </p>
                        </td>
                    </tr>

                    </tbody>
                </table>
            </div>
        );
    }
}


export default PrivacyPolicy;

