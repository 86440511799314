import { select, takeEvery, all, fork, spawn } from "redux-saga/effects";
import watchScanNews from "./scannews/saga";

function* watchAndLog() {
    yield takeEvery("*", function* logger(action) {
        const state = yield select()
        console.debug("action", action)
        console.debug("state after", state)
    })
}

export default function* root() {
    yield all([
        fork(watchAndLog),
        fork(watchScanNews),
    ])
}
