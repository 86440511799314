import * as actions from '../sagas/scannews/actions';

let initialState = {
    loading: false,
    accessToken: {},
    user: {},
    errors: {},
    success: {}
}


export const reducer = (state= initialState, action) => {
    switch(action.type) {
        case actions.CLEAR_MESSAGES:
            return {
                ...state,
                errors: {},
                success: {},
            }
        case actions.GET_ACCESS_TOKEN_SUCCESS:
            return {
                ...state,
                loading: false,
                accessToken: action.data
            }
        case actions.GET_ACCESS_TOKEN_FAILURE:
            return {
                ...state,
                loading: false,
                errors: {...state.errors, "getAccessToken": action.data}
            }
        case actions.GET_USER_BY_PASSWORD_RESET_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.data
            }
        case actions.GET_USER_BY_PASSWORD_RESET_KEY_FAILURE:
            return {
                ...state,
                loading: false,
                errors: {...state.errors, "getUser": action.data}
            }
        case actions.RESET_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: {...state.success, "resetPassword": action.data}
            }
        case actions.RESET_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                errors: {...state.errors, "resetPassword": action.data}
            }
        default:
            return state;
    }
}