import axios from "axios";

const API_URL = process.env.REACT_APP_SCANNEWS_API_URL;


/**
 * Gets the headers.
 *
 * @param token
 * @returns {{Authorization: string, "Content-Type": string}}
 */
function getHeaders(token) {
	return {
		"Content-Type": "application/json",
		"Authorization": `Bearer ${token}`
	};
}

/**
 * Gets the access token.
 *
 * @param username
 * @param password
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getAccessToken(payload) {
	const data = {
		"username": payload["username"],
		"password": payload["password"]
	};
	return axios.post(`${API_URL}/authenticate`, data);
}

/**
 * Gets the user profile.
 *
 * @param token
 * @param payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getUserByUsernameAndPasswordResetKey(token, payload) {
	let params = {
		"username": payload["username"],
		"passwordResetKey": payload["passwordResetKey"]
	};

	console.debug("headers:", getHeaders(token));

	return axios.get(`${API_URL}/v1/users/findByUsernameAndPasswordResetKey`, {headers: getHeaders(token), params: params});
}

/**
 * Reset the user password.
 *
 * @param token
 * @param payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export function resetPassword(token, payload) {
	// console.debug("*** resetPassword: payload =", payload);

	const data = {
		"username": payload["username"],
		"password": payload["password"],
		"passwordResetKey": payload["passwordResetKey"]
	};

	return axios.put(`${API_URL}/v1/users/resetPassword`, data, {headers: getHeaders(token)});
}
