import * as React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import moment from "moment-timezone";
import * as scannewsActions from "../../sagas/scannews/actions";
import "./ScanNews.css";


function Copyright() {
    return (
        <p className="App-footer">
            {"Copyright © ScanNews | Powered by "}
            <a color="inherit" href="/">
                NextMining
            </a>{" "}
            {new Date().getFullYear()}
            {"."}
        </p>
    );
}


function PasswordReset(props) {
    const params = new URLSearchParams(props.location.search);
    const username = params.get("username");
    const passwordResetKey = params.get("key");
    console.debug("*** params: username =", username);
    console.debug("*** params: key =", passwordResetKey);


    const [values, setValues] = React.useState({
        username: username,
        password: "",
        confirmPassword: "",
        isValidPassword: true,
        isValidConfirmPassword: true,
        passwordResetKey: passwordResetKey
    });

    // Similar to componentDidMount and componentDidUpdate:
    React.useEffect(() => {
        props.getUserByPasswordResetKey({
            "username": username,
            "passwordResetKey": passwordResetKey
        });
    }, []);

    const validatePassword = (username, password) => {
        if (password.trim() == "") {
            return false;
        }

        if (username.trim() == password.trim()) {
            return false;
        }

        if (!/^(?=.*\d)(?=.*[a-z]).{7,32}$/.test(password)) {
            return false;
        }
        
        return true;
    };

    const handleChange = (prop) => (event) => {
        const value = event.target.value;
        console.debug(prop, value);

        if (prop == "password") {
            if (validatePassword(values.username, value)) {
                setValues({ ...values, [prop]: value, "isValidPassword": true });
            }
            else {
                setValues({ ...values, [prop]: value, "isValidPassword": false });
            }
        }
        else if (prop == "confirmPassword") {
            if (validatePassword(values.username, value) && values.password == value) {
                setValues({ ...values, [prop]: value, "isValidConfirmPassword": true });
            }
            else {
                setValues({ ...values, [prop]: value, "isValidConfirmPassword": false });
            }
        }
    };

    const handleSubmit = (event) => {
        props.clearMessages();
        event.preventDefault();
        console.debug("*** handleSubmit: values =", values);
        if (!values.isValidConfirmPassword || !values.confirmPassword) {
          return;
        }
        
        props.resetPassword(values);
      };

    return (
        <Container fluid="sm" style={{padding: "30px 10px 10px 10px"}}>
            {props.errors && (props.errors.hasOwnProperty("getUser") || props.errors.hasOwnProperty("resetPassword")) && (
                <Alert key={"danger"} variant={"danger"}>
                {props.errors["resetPassword"]["message"]}
                </Alert>
            )}
            {props.success && (props.success.hasOwnProperty("resetPassword")) && (
                <Alert key={"success"} variant={"primary"}>
                    {/* <Alert.Heading><FormattedMessage id="app.scannews.changedPassword" defaultMessage="Changed the password successfully!" /></Alert.Heading> */}
                    <FormattedMessage id="app.scannews.changedPasswordSuccess" defaultMessage="Changed password successfully." />
                </Alert>
            
            )}
            
            <Row className="justify-content-center" style={{padding: "10px"}}>
                <h1><FormattedMessage id="app.scannews.resetPassword" defaultMessage="Reset Password" /></h1>
            </Row>

            <Row className="justify-content-center" style={{padding: "10px"}}>
                <p>
                    <FormattedMessage id="app.scannews.resetPasswordGuide" 
                        defaultMessage="Change your password. Your password cannot be the same as your username." 
                        values={{
                            date: moment(props.user.passwordResetExpireDate).format("YYYY-MM-DD HH:mm")
                        }} />
                </p>
            </Row>

            <Row className="justify-content-center">
                <Col sm={6}>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Control
                                readOnly
                                type="email"
                                id="inputUsername"
                                defaultValue={username}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                        <FormattedMessage id="app.scannews.newPassword" defaultMessage="New Password"> 
                        {(msg) =>  
                            <Form.Control
                                type="password"
                                id="password"
                                placeholder={msg}
                                isInvalid={!values.isValidPassword}
                                onChange={handleChange("password")}
                            />
                        }
                        </FormattedMessage>
                        </Form.Group>
                        
                        <Form.Group className="mb-3">
                            <FormattedMessage id="app.scannews.confirmPassword" defaultMessage="Confirm Password"> 
                            {(msg) =>  
                            <Form.Control
                                type="password"
                                id="confirmPassword"
                                placeholder={msg}
                                aria-describedby="passwordHelpBlock"
                                isInvalid={!values.isValidConfirmPassword}
                                onChange={handleChange("confirmPassword")}
                            />
                            }
                            </FormattedMessage>
                            <Form.Text id="passwordHelpBlock" muted>
                                <FormattedMessage id="app.scannews.passwordHelper" defaultMessage="Your password must be minimum 7 characters of at least 3 alphabets and 3 numbers,
                                and must not contain spaces or emoji." /> 
                            </Form.Text>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>

            <Row className="justify-content-center" style={{padding: "10px"}}>
                <Button variant="primary" size="lg" onClick={handleSubmit} disabled={props.loading}>
                    {props.loading && (
                        <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    )}
                    <FormattedMessage id="app.scannews.changePassword" defaultMessage="Change Password" />
                </Button>
            </Row>
            

            {/* Footer */}
            <Container className="p-3">
                <Copyright />
            </Container>
            {/* End footer */}
        </Container>
    );
}

const mapStateToProps = (state) => {
    return {
        loading: state.scannews.loading,
        errors: state.scannews.errors,
        success: state.scannews.success,
        user: state.scannews.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        clearMessages: (value) => {
            dispatch(scannewsActions.clearMessages());
        },
        getAccessToken: (value) => {
            dispatch(scannewsActions.getAccessToken(value));
        },
        getUserByPasswordResetKey: (value) => {
            dispatch(scannewsActions.getUserByPasswordResetKey(value));
        },
        resetPassword: (value) => {
            dispatch(scannewsActions.resetPassword(value));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);

