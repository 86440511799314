import React, {Component} from "react";
import {ConnectedRouter} from "connected-react-router";
import {Route} from "react-router-dom";
import Home from "./components/home/Home";
import ScanNewsPolicy from "./components/scannews/Policy";
import ScanNewsPrivacy from "./components/scannews/Privacy";
import ScanNewsTerms from "./components/scannews/Terms";
import ScanNewsPasswordReset from "./components/scannews/PasswordReset";


class App extends Component {

  render() {
    const {history} = this.props;

    return (
        <ConnectedRouter history={history}>
            <Route exact path="/" component={Home} />
            <Route exact path="/scannews/policy" component={ScanNewsPolicy} />
            <Route exact path="/scannews/privacy" component={ScanNewsPrivacy} />
            <Route exact path="/scannews/terms" component={ScanNewsTerms} />
            <Route exact path="/scannews/passwordReset" component={ScanNewsPasswordReset} />
            <Route exact path="/classly/policy" component={ScanNewsPolicy} />
            <Route exact path="/classly/privacy" component={ScanNewsPrivacy} />
            <Route exact path="/classly/terms" component={ScanNewsTerms} />
        </ConnectedRouter>
    );
  }
}

export default App;
