import React, {Component} from "react";
import {FormattedMessage} from "react-intl";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
// import Jumbotron from "react-bootstrap/Jumbotron";
import Card from "react-bootstrap/Card";
// import CardGroup from "react-bootstrap/CardGroup";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from "react-bootstrap/Button";
import logo from "../../assets/images/logo_n_circle.png";
import logoScanNews from "../../assets/images/logo_scannews_new.png";
import logoClassly from "../../assets/images/logo_classly.png";
import "./Home.css";


function Copyright() {
    return (
        <p className="App-footer">
            {"Copyright © "}
            <a color="inherit" href="/">
                NextMining
            </a>{" "}
            {new Date().getFullYear()}
            {"."}
        </p>
    );
}

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    handleProductClick = (name) => async (event) => {
        console.log("*** handleProductClick: props =", this.props.history);

        let url = "";
        if (name === "scannews") {
            url = "https://play.google.com/store/apps/details?id=com.nextmining.reader";
        }
        else if (name === "classly") {
            url = "https://classly.ai/";
        }
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    render() {
        return (
            <React.Fragment>
                <Navbar bg="dark" variant="dark" fixed="top">
                    <Navbar.Brand href="/">
                        {/*<img*/}
                        {/*    alt=""*/}
                        {/*    src={logo}*/}
                        {/*    width="30"*/}
                        {/*    height="30"*/}
                        {/*    className="d-inline-block align-top"*/}
                        {/*/>{' '}*/}
                        NextMining
                    </Navbar.Brand>
                    <Nav className="mr-auto">
                    </Nav>
                    <Nav>
                        <Nav.Link href="#products">Products</Nav.Link>
                        <Nav.Link href="#contact">Contact</Nav.Link>
                    </Nav>

                </Navbar>
                <br />

                <Container fluid className="Home-jumbotron Home-jumbotron-image">
                    <h1 className="header">
                        <FormattedMessage id="app.home.text.hero_content"
                                            defaultMessage="Innovate services with AI"
                        />
                    </h1>
                    <p className="header Home-jumbotron-subtitle">
                        <FormattedMessage id="app.home.text.hero_secondary_content"
                                            defaultMessage="We provide the innovative services with Artificial Intelligence."
                        />
                    </p>
                </Container>

                <div id="body">
                    <Container fluid="md" className="Home-product-container" id="products">
                        <Row>
                            <h4>Products</h4>
                            <br/>
                        </Row>
                        <div>
                        <Row xs={1} md={3} className="g-3">
                            <Col key={1}>
                                <Card onClick={this.handleProductClick("scannews")} style={{height: "360px"}}>
                                    {/*<Card.Img variant="top" src="holder.js/100px160" />*/}
                                    <Card.Body style={{paddingBottom: "100px"}}>
                                        <Card.Title>Scan News</Card.Title>
                                        <Card.Text>
                                            Scan News is the mobile app for learning foreign languages.&nbsp;
                                            <i>"Learn English with News and YouTube!"</i>&nbsp;
                                            Simply click the words you want to know while reading news.
                                        </Card.Text>
                                        <div style={{position: "absolute", bottom: "60px", left: "35%"}}>
                                            <img src={logoScanNews} width={100} height={100} />
                                        </div>
                                    </Card.Body>
                                    <Card.Footer>
                                        <small className="text-muted">View more</small>
                                    </Card.Footer>
                                </Card>
                            </Col>
                            <Col key={2}>
                                <Card onClick={this.handleProductClick("classly")} style={{height: "360px"}}>
                                    {/*<Card.Img variant="top" src="holder.js/100px160" />*/}
                                    <Card.Body style={{paddingBottom: "100px"}}>
                                        <Card.Title>Classly</Card.Title>
                                        <Card.Text>
                                            Online live tutoring service for everyone.
                                            Create your classroom you want to teach or find classroom you want to learn!
                                        </Card.Text>
                                        <div style={{position: "absolute", bottom: "100px", left: "18%"}}>
                                            <img src={logoClassly} width={220} />
                                        </div>
                                    </Card.Body>
                                    <Card.Footer>
                                        <small className="text-muted">View more</small>
                                    </Card.Footer>
                                </Card>
                            </Col>
                            <Col key={3}>
                                <Card style={{height: "360px"}}>
                                    {/*<Card.Img variant="top" src="holder.js/100px160" />*/}
                                    <Card.Body style={{paddingBottom: "100px"}}>
                                        <Card.Title>What is the next?</Card.Title>
                                        <Card.Text>
                                            We are continuously inventing the innovative educational service.
                                        </Card.Text>
                                        <div style={{position: "absolute", bottom: "90px", left: "40%"}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60"
                                                fill="currentColor" className="bi bi-question" viewBox="0 0 16 16">
                                                <path
                                                    d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                                            </svg>
                                        </div>
                                    </Card.Body>
                                    <Card.Footer>
                                        <small className="text-muted">View future</small>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        </Row>
                        </div>
                        <br/>
                        <br/>
                        <Row id="contact">
                            <h4>Contact</h4>
                            <br/>
                        </Row>
                        <div>
                            <p>nextmining@gmail.com</p>
                        </div>
                    </Container>
                </div>


                {/* Footer */}
                <Container className="p-3">
                    <Copyright />
                </Container>
                {/* End footer */}
            </React.Fragment>
        );
    }
}


export default Home;

