import {createStore, applyMiddleware, compose} from "redux";
import createSagaMiddleware from "redux-saga";
import {routerMiddleware} from "connected-react-router";
import {createBrowserHistory} from "history";
import createRootReducer from "./reducers";
import sagas from "./sagas";


export const history = createBrowserHistory();

export default function configureStore(preloadedState) {
    const sagaMiddleware = createSagaMiddleware();
    const store = createStore(
        createRootReducer(history), // root reducer with router state
        preloadedState,
        compose(
            applyMiddleware(
                sagaMiddleware,
                routerMiddleware(history)
            )
        ),
    )
    sagaMiddleware.run(sagas);
    return store;
}
