export const CLEAR_MESSAGES = "CLEAR_SCANNEWS_MESSAGES";

export const GET_ACCESS_TOKEN_REQUEST = "GET_SCANNEWS_ACCESS_TOKEN_REQUEST";
export const GET_ACCESS_TOKEN_SUCCESS = "GET_SCANNEWS_ACCESS_TOKEN_SUCCESS";
export const GET_ACCESS_TOKEN_FAILURE = "GET_SCANNEWS_ACCESS_TOKEN_FAILURE";

export const GET_USER_BY_PASSWORD_RESET_KEY_REQUEST = "GET_SCANNEWS_USER_BY_PASSWORD_RESET_KEY_REQUEST";
export const GET_USER_BY_PASSWORD_RESET_KEY_SUCCESS = "GET_SCANNEWS_USER_BY_PASSWORD_RESET_KEY_SUCCESS";
export const GET_USER_BY_PASSWORD_RESET_KEY_FAILURE = "GET_SCANNEWS_USER_BY_PASSWORD_RESET_KEY_FAILURE";

export const RESET_PASSWORD_REQUEST = "RESET_SCANNEWS_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_SCANNEWS_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_SCANNEWS_PASSWORD_FAILURE";


export const clearMessages = () => {
    return {type: CLEAR_MESSAGES}
}

export const getAccessToken = (payload) => {
    return {type: GET_ACCESS_TOKEN_REQUEST, payload: payload}
}

export const getUserByPasswordResetKey = (payload) => {
    return {type: GET_USER_BY_PASSWORD_RESET_KEY_REQUEST, payload: payload}
}

export const resetPassword = (payload) => {
    return {type: RESET_PASSWORD_REQUEST, payload: payload}
}
