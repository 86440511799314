import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {IntlProvider} from "react-intl";
import {Helmet} from "react-helmet";
import configureStore, {history} from "./configureStore";
import App from "./App";
import messages_en from "./translations/en.json";
import messages_ko from "./translations/ko.json";
import "./App.scss";


const messages = {
    "en": messages_en,
    "ko": messages_ko
};
const language = navigator.language.split(/[-_]/)[0];  // language without region code
const store = configureStore();

ReactDOM.render(
    <IntlProvider locale={language} messages={messages[language]}>
        <Helmet>
            <meta charSet="utf-8" />
            <meta name="theme-color" content="#4789c7" />
            <title>Nextmining - Online education innovator with artificial intelligence</title>
            <link rel="canonical" href="https://nextmining.com/" />
        </Helmet>
        <Provider store={store}>
            <App history={history} />
        </Provider>
    </IntlProvider>,
    document.getElementById("root")
);

