import { call, put, takeEvery, select } from "redux-saga/effects";
import { push } from "connected-react-router";
import * as actions from "./actions";
import * as Api from "../../apis/api";
import LocalStorage from '../../helpers/LocalStorage';

const NORMAL_CLIENT_USERNAME = process.env.REACT_APP_NORMAL_CLIENT_USERNAME;
const NORMAL_CLIENT_SECRET_KEY = process.env.REACT_APP_NORMAL_CLIENT_SECRET_KEY;


function* getAccessToken(action) {
    const payload = action.payload;

    try {
        const {data: response} = yield call(Api.getAccessToken, payload);
        console.log("*** getAccessToken: response =", response);

        yield LocalStorage.setItem("scannews_accessToken", response["token"]);
    
        yield put({type: actions.GET_ACCESS_TOKEN_SUCCESS, data: response});
    } catch (error) {
        console.error(error);
        let err = null;
        !error.message ? err = {"message": error} : err = error;

        yield put({type: actions.GET_ACCESS_TOKEN_FAILURE, data: err});
    }
}

function* getUserByPasswordResetKey(action) {
    const payload = action.payload;

    try {
        var token = LocalStorage.getItem("scannews_accessToken");
        if (!token) {
            const {data: accessToken} = yield call(Api.getAccessToken, {"username": NORMAL_CLIENT_USERNAME, "password": NORMAL_CLIENT_SECRET_KEY});
            
            token = accessToken["token"];
            console.debug("*** getAccessToken: token =", token);
            yield LocalStorage.setItem("scannews_accessToken", token);
        }

        console.debug("*** token =", token);
        
        const {data: response} = yield call(Api.getUserByUsernameAndPasswordResetKey, token, payload);
        console.log("*** getUserByUsernameAndPasswordResetKey: response =", response);
    
        yield put({type: actions.GET_USER_BY_PASSWORD_RESET_KEY_SUCCESS, data: response});
    } catch (error) {
        console.error(error);
        let err = null;
        !error.message ? err = {"message": error} : err = error;

        yield put({type: actions.GET_USER_BY_PASSWORD_RESET_KEY_FAILURE, data: err});
    }
}

function* resetPassword(action) {
    const payload = action.payload;

    try {
        var token = LocalStorage.getItem("scannews_accessToken");
        if (!token) {
            const {data: accessToken} = yield call(Api.getAccessToken, {"username": NORMAL_CLIENT_USERNAME, "password": NORMAL_CLIENT_SECRET_KEY});
            
            token = accessToken["token"];
            console.debug("*** getAccessToken: token =", token);
            yield LocalStorage.setItem("scannews_accessToken", token);
        }

        console.debug("*** token =", token);

        const {data: response} = yield call(Api.resetPassword, token, payload);
        console.log("*** resetPassword: response =", response);
    
        yield put({type: actions.RESET_PASSWORD_SUCCESS, data: response});
    } catch (error) {
        console.error(error);
        let err = null;
        !error.message ? err = {"message": error} : err = error;

        yield put({type: actions.RESET_PASSWORD_FAILURE, data: err});
    }
}


export default function* saga() {
    yield takeEvery(actions.GET_ACCESS_TOKEN_REQUEST, getAccessToken);
    yield takeEvery(actions.GET_USER_BY_PASSWORD_RESET_KEY_REQUEST, getUserByPasswordResetKey);
    yield takeEvery(actions.RESET_PASSWORD_REQUEST, resetPassword);
}
