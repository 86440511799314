import React, {Component} from "react";
import "./ScanNews.css";


class Privacy extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        return (
            <div>
                <figure className="highlight">
                    <h2>Privacy Policy</h2>
                </figure>
                <table className="table">
                    <tbody>
                    <tr>
                        <td>
                            <p>
                                Thank you for using service!
                                &nbsp;We wrote this policy to help you understand what information we collect, how we
                                use it, and what choices you have.
                                &nbsp;Because we’re an internet service, some of the concepts below are a little
                                technical,
                                &nbsp;but we’ve tried our best to explain things in a simple and clear way.
                                &nbsp;We welcome your questions and comments on this policy.
                            </p>
                        </td>
                    </tr>


                    <tr>
                        <td>
                            <h4>What information do we collect?</h4>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>
                                We collect information in a few different ways:
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <h5>1. When you give it to us or give us permission to obtain it</h5>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>
                                When you sign up for or use our products, you voluntarily give us certain information.
                                &nbsp;This can include your name, the email address you used to sign up,
                                &nbsp;and any other information you provide us.
                                &nbsp;And if you choose to buy something on ScanNews, you provide us with payment
                                information,
                                &nbsp;contact information (ex., address and phone number), and what you purchased.
                            </p>
                            <br />
                                <p>
                                    You also may give us permission to access your information in other services.
                                    &nbsp;For example, you may link your Facebook, Google or Naver account to
                                    NextMining,
                                    &nbsp;which allows us to obtain information from those accounts.
                                    &nbsp;The information we get from those services often depends on your settings or
                                    their privacy policies,
                                    &nbsp;so be sure to check what those are.
                                </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <h5>2. We also get technical information when you use our products</h5>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>
                                These days, whenever you use a website, mobile application, or other internet service,
                                &nbsp;there’s certain information that almost always gets created and recorded
                                automatically.
                                &nbsp;The same is true when you use our products. Here are some of the types of
                                information we collect:
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <ul>
                                <li>
                                    Log data. When you use ScanNews, our servers automatically record information (“log
                                    data”),
                                    &nbsp;including information that your browser sends whenever you visit a website or
                                    your mobile app sends when you’re using it.
                                    &nbsp;This log data may include your Internet Protocol address, the address of the
                                    web pages you visited
                                    &nbsp;that had ScanNews features, browser type and settings, the date and time of
                                    your request, how you used ScanNews, and cookie data.
                                </li>
                                <li>
                                    Device information. In addition to log data, we may also collect information
                                    &nbsp;about the device you’re using ScanNews on, including what type of device it
                                    is,
                                    &nbsp;what operating system you’re using, device settings, unique device
                                    identifiers, and crash data.
                                    &nbsp;Whether we collect some or all of this information often depends on what type
                                    of device you’re using and its settings.
                                    &nbsp;To learn more about what information your device makes available to us,
                                    &nbsp;please also check the policies of your device manufacturer or software
                                    provider.
                                </li>
                            </ul>
                        </td>
                    </tr>

                    {/*<tr>*/}
                    {/*    <td>*/}
                    {/*        <h5>3. Our partners and advertisers may share information with us</h5>*/}
                    {/*    </td>*/}
                    {/*</tr>*/}
                    {/*<tr>*/}
                    {/*    <td>*/}
                    {/*        <p>*/}
                    {/*            We may get information about you and your activity off NextMining from advertisers,*/}
                    {/*            &nbsp;partners and other third parties we work with.*/}
                    {/*        </p>*/}
                    {/*    </td>*/}
                    {/*</tr>*/}


                    <tr>
                        <td>
                            <h4>How do we use the information we collect?</h4>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>
                                We use the information we collect to provide our products to you and make them better,
                                &nbsp;develop new products, and protect ScanNews and our users.
                                &nbsp;For example, we may log how often people use two different versions of a product,
                                &nbsp;which can help us understand which version is better.
                                &nbsp;Also we may log which words or articles people searched or viewed, which can
                                analyze those data and
                                &nbsp;provide you better service.
                                &nbsp;If you make a purchase on ScanNews, we’ll save your payment information and
                                contact information
                                &nbsp;so that you can use them the next time you want to buy something on ScanNews.
                            </p>
                        </td>
                    </tr>


                    <tr>
                        <td>
                            <h4>How do you delete the personal information we collect?</h4>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>
                                We destroy personal information immediately after the user deletes his or her account from the service.
                                &nbsp;The user can delete the account including personal information in the user settings menu in the service.
                                &nbsp;The user information such as user profile information, user stored data(ex., my vocabulary, my favorite list and purchasing history, etc.) will be removed from the database.
                                &nbsp;After the user deletes the account, the user cannot revoke the account again.
                            </p>
                        </td>
                    </tr>


                    <tr>
                        <td>
                            <h4>How do we make changes to this policy?</h4>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>
                                We may change this policy from time to time, and if we do we will post any changes on
                                this page.
                                If you continue to use ScanNews after those changes are in effect, you agree to the
                                revised policy.
                                If the changes are significant, we may provide more prominent notice or get your consent
                                as required by law.
                            </p>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <h4>Use of YouTube API Services</h4>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>
                                ScanNews application is using YouTube API services for playing most of the video
                                contents.
                                Hence, ScanNews application follows YouTube's Terms of Service (<a
                                href="https://www.youtube.com/t/terms">https://www.youtube.com/t/terms</a>).
                                ScanNews users also agree to a Google Privacy Policy (<a
                                href="http://www.google.com/policies/">http://www.google.com/policies/</a>) as we are
                                using YouTube API service.
                            </p>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <em>
                                Effective: June 10, 2016
                            </em>
                        </td>
                    </tr>

                    </tbody>
                </table>
            </div>
        );
    }
}


export default Privacy;

