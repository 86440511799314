import React, {Component} from "react";
import Container from "react-bootstrap/Container";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Privacy from "./Privacy";
import TermsOfService from "./Terms";
import "./ScanNews.css";


function Copyright() {
    return (
        <p className="App-footer">
            {"Copyright © "}
            <a color="inherit" href="/">
                NextMining
            </a>{" "}
            {new Date().getFullYear()}
            {"."}
        </p>
    );
}

class Policy extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        return (
            <Container style={{marginTop: "10px"}}>
                <div />
                <Tabs
                    defaultActiveKey="terms-of-service"
                    transition={false}
                    className="mb-3"
                >
                    <Tab eventKey="terms-of-service" title="Terms of Service">
                        <TermsOfService />
                    </Tab>
                    <Tab eventKey="privacy-policy" title="Privacy Policy">
                        <Privacy />
                    </Tab>
                </Tabs>

                {/* Footer */}
                <Container className="p-3">
                    <Copyright />
                </Container>
                {/* End footer */}
            </Container>
        );
    }
}


export default Policy;

